import React from 'react'
import { Helmet } from 'react-helmet'

import Logrocket from '../../components/LogRocket'
import LandingWithSplash from '../../components/LandingWithSplash'
import previewImage from '../../assets/preview.png'
import '../../styles.scss'

const SplashLandingPage = () => {
  return (
    <Logrocket>
      <main className='zsf-main'>
        <Helmet>
          {/* <meta property="og:url" content={ogUrl} /> */}
          {/* <meta property="og:type" content={props.type} /> */}
          <meta property="og:title" content="Frank Pepe's New Haven" />
          {/* <meta property="og:description" content={props.desc} /> */}
          <meta property="og:image" content={previewImage} />
        </Helmet>
        <LandingWithSplash />
      </main>
    </Logrocket>
  )
}

export default SplashLandingPage
